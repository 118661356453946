@import url("https://fonts.cdnfonts.com/css/franklin-gothic");
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Sora:wght@100..800&display=swap");
@import "leaflet/dist/leaflet.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background: black;
  /* background: rgba(0, 0, 0, 0.493); */
  transition: all 0.3s;
  backdrop-filter: blur(20px);
  /* position: relative; */
}

/* .navbar.scrolled {
  background: rgba(0, 0, 0, 0.493); 
  backdrop-filter: blur(60px);
} */

.nav-link {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #fff !important;

  margin: 0 15px;
}

.nav-item {
  position: relative;
}

.nav-buttons select {
  background: transparent;
  border: none;
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
}

.nav-buttons select option {
  background: black !important;
}

.nav-link span {
  background: #29acff4d;
  border: 1px solid #28abfe;
  border-radius: 50%;
  padding: 10px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  top: calc(100% + -0px);
  right: 0;
  background-color: #000 !important;
  border-top: none;
  z-index: 1;
  opacity: 0; /* Hide initially */
  transform: translateY(-10px); /* Initial position above */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  width: 250px !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px;
  border-bottom: 1px solid #6e6e6e;
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  display: block;
}

.nav-item:hover .dropdown-menu {
  display: block;
  opacity: 1; /* Show with smooth transition */
  transform: translateY(1px); /* Adjust dropdown position */
  border: none;
}

.nav-item:hover .dropdown-menu ul li:hover {
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
}

.bi-person-circle {
  font-size: 30px;
}
.bi-box-arrow-right {
  font-size: 20px;
  padding-left: 3%;
}

.nav-link.active {
  background: linear-gradient(90deg, #28abfe 0%, #186798 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link:hover {
  background: linear-gradient(90deg, #28abfe 0%, #186798 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first-btn {
  border: 1px solid #28abfe;
  background: #29acff4d;
  border-radius: 25px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 35px;
}

.first-btn:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.main {
  background-image: url("../public/images-local/banner.png");
  background-size: cover;
  padding: 10%;
  /* position: absolute;
  top: 0;left: 0; */
}

.main h1 {
  font-family: "Franklin Gothic", sans-serif;
  font-size: 50px;
  font-weight: 900;
  line-height: 70px;
  text-align: left;
  background: linear-gradient(90deg, #e3e3e3 0%, #13a4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main p {
  font-family: "Euclid Circular A", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  color: #fff;
}

.btn-1 {
  padding: 8px 35px;
  border: 2px solid #28abfe;
  color: #28abfe;
  border-radius: 30px;
  background: transparent;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;
  margin-right: 3%;
}

.btn-1:hover {
  color: #fff;
  border: 2px solid #0b3d5d;
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  transition: all 0.3s ease-in-out;
}

.btn-2 {
  padding: 8px 35px;
  border-radius: 30px;
  color: #fff;
  border: 2px solid #0b3d5d;
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;
  margin-right: 3%;
}

.btn-2:hover {
  border: 2px solid #28abfe;
  color: #28abfe;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.search-group {
  display: flex;
  padding: 3%;
  background: #ffffff80;
  border: 2px solid #28abfe;
  border-radius: 30px;
}

.search-group input {
  width: 80%;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding-left: 3%;
}

.search-group input::placeholder {
  color: #fff;
}

.search-group select {
  width: 15%;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  /* padding-right: 3%; */
}

.search-group select option {
  background: #000;
}

.search-mobile button {
  padding: 12px 45px;
  border: 1px solid;
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  border: none;
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
}

.works {
  background: #000;
  padding: 5%;
}

.work-header h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #fff;
}

.work-header h1 span {
  color: #28abfe;
}

.work-header p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #e7e7e799;
}

.work-row {
  padding-top: 3%;
}

.work-card {
  padding: 5%;
  border: 1px solid #a1aebf;
  border-radius: 8px;
  margin-bottom: 3%;
}

.work-card h5 {
  font-family: "Franklin Gothic";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #fff;
  margin: 2% 0;
}

.work-card p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff99;
}

.how {
  background: url("../public/images-local/Stars.png");
  padding: 5%;
}

.how-con {
  position: relative;
}

.how-img img {
  width: 100%;
}

@media screen and (max-width: 575px) {
  .how-img video {
    width: 100%;
    height: 100%;
  }
}

.youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how-left h5 {
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  color: #fff;
}

.how-left h5 span {
  color: #28abfe;
}

.how-card {
  padding: 4%;
  background: #d9d9d91a;
  border-left: 3px solid #ffffff;
  margin-bottom: 4%;
}

.history-card {
  padding: 3%;
  background: #d9d9d91a;
  border-left: 3px solid #ffffff;
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-card h6 {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  margin-bottom: 7px;
}

.history-card p {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #ffffffcc;
  margin-bottom: 0;
}

.history-card i {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.view {
  font-family: "DM Sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  text-align: right;
  color: #fff;
}

.how-card h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  margin-bottom: 15px;
}

.how-card p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
}

.features {
  background: #000;
  padding: 5%;
  position: relative;
}

.ellipse {
  position: absolute;
  bottom: 0;
  left: -10%;
  background: linear-gradient(
    180deg,
    rgba(24, 103, 152, 0.3) 0%,
    rgba(40, 171, 254, 0.3) 100%
  );
  width: 400px;
  height: 400px;
  border-radius: 50%;
  filter: blur(100px);
}

.feature-left h1 {
  font-family: "Quicksand", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  color: #fff;
}

.feature-left h1 span {
  color: #28abfe;
}

.feature-left p {
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff99;
  padding: 3% 0;
}

.feature-left button {
  margin-top: 3%;
  padding: 12px 25px;
  background: transparent;
  border-radius: 25px;
  border: 1px solid #28abfe;
  color: #28abfe;
  font-family: "Archivo";
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: left;
}

.feature-left button:hover {
  background: #28abfe;
  color: #1a365d;
  transition: all 0.3s ease-in-out;
}

.feature-card {
  border: 1px solid #a1aebf;
  border-radius: 4px;
  padding: 5%;
  margin-bottom: 4%;
}

.feature-card img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 4% !important;
}

.feature-card span {
  padding: 4px 8px;
  border-radius: 4px;
  background: #28abfe;
  color: #1a365d;
  font-family: "Archivo";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}

.feature-card h2 {
  font-family: "Quicksand";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #fff;
  margin-top: 4%;
}

.feature-card h6 {
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #728095;
  margin-bottom: 0;
  cursor: pointer;
}

.know {
  padding: 5%;
  background: linear-gradient(90deg, #082233 0%, #000000 100%);
}

.know h1 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #fff;
}

.know h1 span {
  color: #28abfe;
}

.know p {
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.know-button {
  padding-top: 3%;
  display: flex;
  justify-content: center;
}

.know-button button {
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
}

.know-button button:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.faq {
  padding: 5%;
  background: url("../public/images-local/banner3.png");
  background-size: cover;
}

.faq h1 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.faq h1 span {
  color: #28abfe;
}

.faq-row {
  padding-top: 4%;
}

.faq-card {
  box-shadow: 0px 5px 16px 0px #080f340f;
  background: #ffffff;
  border-radius: 16px;
  padding: 4%;
  margin-bottom: 3%;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-header h4 {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #170f49;
  margin-bottom: 0;
}

.faq-header .span {
  font-size: 18px;
  font-weight: 700;
  color: #6f6c90;
  padding: 0px 10px;
  background: #f7f7ff;
  border-radius: 8px;
  cursor: pointer;
}
.faq-header .span.faq-active {
  background: #28abfe;
  color: #fff;
}

.faq p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #6f6c90;
  margin-top: 3%;
}

.footer-top {
  padding: 3%;
  background: #212131;
}

.f-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-row h3 {
  font-family: "Sora";
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: left;
  color: #fff;
}

.f-row input {
  width: 100%;
  padding: 2.5%;
  border-radius: 8px;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  color: #909098;
  border: none;
  margin-bottom: 2%;
}

.f-row input:focus {
  outline: none;
  border: none;
}

.f-row button {
  padding: 12px 35px;
  font-family: "Sora";
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  color: #fff;
  background: #0a8fef;
  border: 1px solid #0a8fef;
  border-radius: 8px;
}

.footer {
  background: #14141f;
  padding-top: 3%;
}

.footer-col {
  margin-bottom: 3%;
}

.footer-col p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #fff;
  margin: 6% 0;
}

.footer-social {
  margin-top: 6%;
}

.footer-social i {
  padding: 5px 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 4%;
  cursor: pointer;
}

.bi-facebook:hover {
  color: #45c4e9;
}
.bi-twitter:hover {
  color: #03bff8;
}
.bi-instagram:hover {
  color: #ff6363;
}

.footer-col h3 {
  font-family: "Sora";
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
}

.footer-col li {
  list-style: none;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer-col li:hover {
  color: #28abfe;
}

.footer-bottom {
  padding-bottom: 1%;
  padding-top: 1%;
  border-top: 1px solid #424255;
}

.footer-bottom p {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #f3f3ff;
}

.location {
  background: url("../public/images-local/banner2.png");
  background-size: cover;
  padding: 5%;
}

.location-1 {
  background: linear-gradient(
    180deg,
    rgba(8, 34, 51, 0.2) 0%,
    rgba(24, 103, 152, 0.2) 100%
  );
  border-radius: 10px;
  padding: 5% 0;
}

.location-2 {
  background: linear-gradient(
    180deg,
    rgba(8, 34, 51, 0.3) 0%,
    rgba(24, 103, 152, 0.3) 100%
  );
  border-radius: 10px;
  padding: 7% 0;
}

.location-3 {
  background: linear-gradient(
    180deg,
    rgba(8, 34, 51, 0.5) 0%,
    rgba(24, 103, 152, 0.5) 100%
  );
  border-radius: 10px;
  padding: 5%;
}

.loc-icon {
  display: flex;
  justify-content: center;
}

.location-3 h6 {
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

.location-3 h2 {
  font-family: "Franklin Gothic";
  font-size: 26px;
  font-weight: 700;
  line-height: 29.48px;
  text-align: center;
  background: linear-gradient(90deg, #28abfe 0%, #a9ddff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5%;
}

.loc-text {
  padding-bottom: 5px;
  border-bottom: 1px dotted #ffffff80;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.loc-text span {
  font-family: "Sora";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.blur {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login {
  background: linear-gradient(
    180deg,
    rgba(8, 34, 51, 0.2) 0%,
    rgba(24, 103, 152, 0.2) 100%
  );
  border-radius: 10px;
  padding-top: 5%;
  padding-left: 5% !important;
}

.contact {
  padding: 5% !important;
}

.login-img {
  width: 100%;
}

.login-img img {
  width: 100%;
  object-fit: contain;
}

.login-left h1 {
  font-family: "Sora";
  font-size: 48px;
  font-weight: 600;
  line-height: 60.48px;
  color: #fff;
  margin: 10px 0;
}

.login-left h6 {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  color: #fff;
}

.login-form {
  margin-top: 5%;
}

.login-item {
  margin-bottom: 3%;
}

.pay-item {
  margin-bottom: 5%;
}

.login-item label {
  display: block;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}

.login-item input {
  width: 100%;
  padding: 2.5%;
  border-radius: 10px;
  background: #ffffffcc;
  color: #0c334c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  border: 0;
}

.pay-item input {
  padding: 4%;
}

.login-item input:focus {
  border: none;
  outline: none;
}

.login-group {
  width: 100%;
  /* padding: 2.5%; */
  border-radius: 10px;
  background: #ffffffcc;
  color: #0c334c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  border: 0;
  display: flex;
}

.login-group input {
  background: transparent;
}

.login-group span {
  padding: 12px;
  height: 100%;
  font-size: 20px;
  cursor: pointer;
}

.login-item p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: right;
  color: #fff;
  margin-top: 5px;
}

.login-check label {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
  margin-left: 10px;
}

.login-check input {
  border: 1px solid #fff;
}

.login-check input[type="file"] {
  border: 0;
  padding: 0.5%;
  border-radius: 10px;
  background: #ffffffcc;
  color: #0c334c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
}

.login-btn button {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(23, 128, 195, 0.8) 0%,
    rgba(11, 61, 93, 0.8) 100%
  );
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: center;
  color: #fff;
  border: 0;
  margin-top: 3%;
  margin-bottom: 5px;
}

.contact-btn {
  display: flex;
  justify-content: center;
}

.contact-btn button {
  padding: 12px 45px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(23, 128, 195, 0.8) 0%,
    rgba(11, 61, 93, 0.8) 100%
  );
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: center;
  color: #fff;
  border: 0;
  margin-top: 3%;
}

.register {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: center;
  color: #fff;
}

.register span {
  color: #28abfe;
  text-decoration: none !important;
}

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 70px;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  animation: zoomInOut 3s infinite alternate;
}

.float img {
  width: 100%;
  height: 100%;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.2); /* Zoom in halfway through the animation */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.otp-timer-text,
.otp-timer-button {
  font-family: "Sora", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  font-style: none;
}

.footer-imgs img {
  padding-right: 5%;
}

.footer-imgs {
  display: flex;
  justify-content: center;
}

.login-form h6 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #fff;
  margin-top: 3%;
}

.plan-header h5 {
  font-family: "Sora";
  font-size: 26px;
  font-weight: 600;
  line-height: 32.76px;
  text-align: center;
  color: #a9ddff;
}

.plan-header h2 {
  font-family: "Sora";
  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: center;
  color: #fff;
}

.plan-header span {
  background: linear-gradient(90deg, #7ecdfe 0%, #28abfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.plan-header h6 {
  font-family: "Sora";
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: center;
  color: #ffffff99;
}

.plan-row {
  padding: 5%;
}

.plan-left {
  border-right: 1px dotted #ffffff;
  /* padding-bottom: 4%; */
  padding: 4%;
}

.plan-right {
  padding: 4%;
}

.plan-left h5,
.plan-right h5 {
  font-family: "Sora";
  font-size: 22px;
  font-weight: 600;
  line-height: 27.72px;
  text-align: left;
  color: #fff;
}

.plan-left li {
  font-family: "Sora";
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #fff;
}

.profile-tabs {
  border-bottom: 1px solid #ffffff;
}

.profile-links {
  background: transparent;
  border: 0;
  padding: 10px 25px;
  font-family: "Sora";
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.profile-links.profile-active {
  border-bottom: 3px solid #45c4e9;
  background: linear-gradient(180deg, #a9ddff 0%, #28abfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.profile-top {
  display: flex;
  padding: 3% 0;
  justify-content: space-between;
  align-items: center;
}

.profile-top h5 {
  font-family: "Sora";
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  color: #dff3ff;
}

.profile-top span {
  font-family: "Sora";
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 5px 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.profile-item {
  margin-bottom: 20px;
}

.profile-item h6 {
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #ffffffcc;
  margin-bottom: 3px;
}

.profile-item p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}

.details {
  background: url("../public/images-local/location-2.png");
  background-size: cover;
  padding: 5%;
}

.detail-search {
  padding: 5%;
  background: linear-gradient(
      94.93deg,
      rgba(24, 103, 152, 0.5) 17.82%,
      rgba(8, 34, 51, 0.5) 87.94%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  box-shadow: 3px 4px 12px 0px #28abfe1a, 13px 17px 21px 0px #28abfe17,
    29px 38px 29px 0px #28abfe0d, 52px 67px 34px 0px #28abfe03,
    81px 105px 37px 0px #28abfe00;
  border-radius: 10px;
  margin-bottom: 4%;
}

.detail-search h5 {
  font-family: "Quicksand";
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.location-group {
  display: flex;
  padding: 3%;
  background: #ffffff80;
  border: 2px solid #28abfe;
  border-radius: 30px;
}

.location-group input {
  width: 80%;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-left: 3%;
}

.location-group input::placeholder {
  color: #fff;
}

.location-group select {
  width: 30%;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* padding-right: 3%; */
}

.location-group select option {
  background: #000;
}

.search-location button {
  padding: 8px 25px;
  border: 1px solid;
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  border: none;
  color: #fff;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  border-radius: 30px;
}

.footer-social {
  margin-top: 6%;
  display: flex;
}

.social-img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cookie {
  background: #000;
  padding: 5%;
}

.cookie h1 {
  background: linear-gradient(180deg, #a9ddff 0%, #28abfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 700;
}

.cookie p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  color: #fff;
}

.cookie h4 {
  background: linear-gradient(180deg, #a9ddff 0%, #28abfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  margin: 2% 0;
}

.cookie li {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
}

.cookie-table {
  overflow: auto;
  border: 1px solid #d9d9d9;
  margin: 3% 0;
  width: 100%;
}

.cookie-table table {
  color: #fff;
  border-collapse: collapse;
  width: 100%;
  min-width: 970px;
  border-spacing: 0;
}

.cookie-table table tr {
  border-bottom: 1px solid #d9d9d9;
  object-fit: contain;
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
}

.cookie-table table td {
  padding: 10px;
  max-width: 350px;
  border: 1px solid #d9d9d9;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.cookie table th {
  padding: 10px;
  border: 1px solid #d9d9d9;
  background: #ffffff25;
  max-width: 350px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  vertical-align: top;
}

.cookie-table table tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.request-access-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
  font-family: "Euclid Circular A", sans-serif;
}

.access-icon {
  font-size: 3rem;
  color: #73a8e4;
  margin-bottom: 1rem;
  animation: bounce 1.2s infinite;
}

.request-access-box h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  color: #333;
}

.request-access-box p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}
.prompt-btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.deny-button {
  background: linear-gradient(90deg, #c12d2d 0%, #e00c01 100%);
  border: none;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.access-button {
  background: linear-gradient(90deg, #1780c3 0%, #0b3d5d 100%);
  border: none;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.access-button:hover {
  background-color: #357ab8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
